<template>
  <div class="map_box">
    <div class="input_form">
      <img src="../assets/search.png" class="input_form_img" />
      <input
        class="input_form_input"
        v-model="datas.waybill_no"
        placeholder="请输入提单号/船名/呼号/MMSI/IMO"
        type="text"
        autofocus
      />
      <Button
        @click="toLocation"
        class="input_form_button"
        color="#537eec"
        type="primary"
        >搜索</Button
      >
    </div>
    <div id="map1"></div>
  </div>
</template>
  <script setup>
import { onMounted, reactive } from "vue";
import "leaflet/dist/leaflet.css";
import L from "leaflet";
import { Button } from "ant-design-vue";
import { useRouter } from "vue-router";
const datas = reactive({
  waybill_no: "",
  mappath: "https://gac-geo.googlecnapps.cn/maps/vt?lyrs=m&x={x}&y={y}&z={z}", //瓦片地址
});
const router = useRouter();
onMounted(() => {
  var map = L.map("map1", { zoomControl: false }).setView(
    [37.772, -122.214],
    13
  );
  L.tileLayer(datas.mappath, {
    maxZoom: 4,
  }).addTo(map);
});
const toLocation = () => {
  console.log(datas.waybill_no, "datas.waybill_no");
  router.push({
    path: "/location",
    query: {
      waybill_no: datas.waybill_no,
    },
  });
};
</script>
  <style lang="less">
.map_box {
  width: 100%;
  height: 100%;
  line-height: 100vh;
  display: flex;
  justify-content: center;
}
#map1 {
  width: 100vw;
  height: 100vh;
}
.input_form {
  position: fixed;
  width: 35%;
  z-index: 9999;
  margin-top: 12%;
  height: 50px;
  justify-content: space-between;
  border-color: rgb(255, 255, 255);
  font-size: 17px;
  line-height: 40px;
  display: inline-flex;
  align-items: center;
  cursor: pointer;
  text-align: left;
  border-color: rgba(34, 34, 34, 0.1);
  border-radius: 9px;
  background: #ffffff;
  box-shadow: 0 0 25px 1px;
  &_img {
    height: 25px;
    width: 25px;
    margin-left: 10px;
  }
  &_input {
    border-color: transparent;
    box-shadow: none;
    outline: none;
    padding: 2px 10px 2px 10px;
    border-radius: 8px;
    width: 60%;
  }
  &_button {
    float: right;
    height: 50px !important;
    width: 140px !important;
    //   line-height: 50px;
  }
  .ant-btn-primary {
    color: #fff;
    border-color: #537eec !important;
    background: #537eec !important;
    border-top-right-radius: 9px;
    border-bottom-right-radius: 9px;
    text-shadow: 0 -1px 0 rgb(0 0 0 / 12%);
    // box-shadow: 0 2px 0 rgb(0 0 0 / 5%);
  }
}
.img {
  margin-right: 10px;
}
a.ant-btn {
  padding-top: 0.01px !important;
  line-height: 40px !important;
}
</style>