<template>
  <div>
    <Spin :spinning="datas.spinning == 'true' ? true : false">
      <!-- 搜索input -->
      <div
        class="showSetting_right"
        v-if="datas.showMapSetting"
        @click="datas.showMapSetting = false"
      >
        <RightOutlined :style="{ color: '#ffffff' }" />
      </div>
      <div
        class="showSetting_left"
        v-if="!datas.showMapSetting"
        @click="datas.showMapSetting = true"
      >
        <LeftOutlined :style="{ color: '#ffffff' }" />
      </div>
      <!-- 设置【地图样式，轨迹播放】 -->
      <div
        class="map_setting"
        v-if="datas.showMapSetting"
        :style="{ margin: datas.mapsettingWidth }"
      >
        <div class="map_seting_mapstyle">
          <img :src="mapstyle" style="width: 23px; height: 23px" />

          <RadioGroup
            @change="maptypeChange"
            name="radioGroup"
            v-model:value="datas.mapTypeactiveKey"
          >
            <Radio
              value="normal"
              :class="{
                'wx-color': datas.mapTypeactiveKey != 'normal',
                'pt-color': datas.mapTypeactiveKey == 'normal',
              }"
            >
              地图</Radio
            >
            <Radio
              value="satellite"
              :class="{
                'wx-color': datas.mapTypeactiveKey != 'normal',
                'pt-color': datas.mapTypeactiveKey == 'normal',
              }"
              >卫星地图</Radio
            >
          </RadioGroup>
        </div>
        <div class="map_setting_video">
          <img :src="videoplayer" style="width: 30px; height: 30px" />
          <span
            @click="showVideoPlayers"
            style="margin-left: 8px; cursor: pointer"
            :class="{
              'wx-color': datas.mapTypeactiveKey != 'normal',
              'pt-color': datas.mapTypeactiveKey == 'normal',
            }"
            >轨迹播放</span
          >
        </div>
        <div class="map_setting_notice">
          <img :src="notice" style="width: 25px; height: 25px" />

          <span style="margin-left: 13px; cursor: pointer">消息中心</span>
        </div>
      </div>
      <!-- 搜索区域 -->
      <div class="search_content">
        <img
          style="
            width: 27px;
            height: 27px;
            margin-left: 80%;
            margin-top: -5px;
            cursor: pointer;
            visibility: hidden;
          "
          :src="datas.playsrc"
        />

        <InputSearch
          v-model:value="datas.searchValue"
          placeholder=" 请输入MMSI/提单号查询"
          style="width: 100%; margin-top: -20px"
          size="large"
          @search="onSearch"
        />
        <div
          class="showInfo_right"
          v-if="!datas.showInfo"
          @click="datas.showInfo = true"
        >
          <RightOutlined :style="{ color: '#ffffff' }" />
        </div>

        <div
          class="showInfo_left"
          v-if="datas.showInfo"
          @click="datas.showInfo = false"
        >
          <LeftOutlined :style="{ color: '#ffffff' }" />
        </div>
        <!-- 船舶基本信息 -->
        <div class="shipInfo" v-show="datas.showInfo">
          <div class="shipInfo_name">
            {{ datas.info.nameEn }}<span>【已靠泊】</span>
          </div>
          <Tabs v-model:activeKey="datas.activeKey">
            <TabPane key="1" tab="AIS信息">
              <div class="shipInfo_trajectory">
                <div class="shipInfo_trajectory_title">
                  <BadgeRibbon
                    text="当前航次"
                    color="#537eec"
                    placement="start"
                  >
                  </BadgeRibbon>
                </div>
                <div class="shipInfo_trajectory_path">
                  <div class="shipInfo_trajectory_path_qsd">
                    <div class="shipInfo_trajectory_path_qsd_title">
                      {{ datas.voyage_number.destination }}
                    </div>
                    <div class="shipInfo_trajectory_path_qsd_time">
                      {{ datas.voyage_number.destinationTime }}
                    </div>
                  </div>
                  <swap-right-outlined
                    style="font-size: 30px; color: #2d48c3"
                  />
                  <div class="shipInfo_trajectory_path_mdd">
                    <div class="shipInfo_trajectory_path_mdd_title">
                      {{ datas.voyage_number.placeofOrigin }}
                    </div>
                    <div class="shipInfo_trajectory_path_mdd_time">
                      {{ datas.voyage_number.placeofOriginTime }}
                    </div>
                  </div>
                </div>
                <div class="shipInfo_trajectory_title">
                  <BadgeRibbon
                    text="当前位置"
                    color="#537eec"
                    placement="start"
                  >
                  </BadgeRibbon>
                </div>
                <div class="shipInfo_trajectory_currentplace">
                  <compass-outlined style="font-size: 20px; color: #2d48c3" />
                  <span>{{ datas.voyage_number.eventPlace }}</span>
                  <span>2022-10-15 14:18</span>
                </div>
                <div class="shipInfo_trajectory_title">
                  <BadgeRibbon
                    text="船舶信息"
                    color="#537eec"
                    placement="start"
                  >
                  </BadgeRibbon>
                </div>
                <!-- 船舶基本信息 -->
                <div class="shipInfo_trajectory_infocell">
                  <ul class="shipInfo_trajectory_ul">
                    <li class="shipInfo_trajectory_li">
                      <p>箱量:</p>
                      <p>建造日期:</p>
                    </li>
                    <li class="shipInfo_trajectory_li">
                      <p>船籍: {{ datas.info.flagName }}</p>
                      <p>吃水: {{ datas.info.draught }}</p>
                    </li>
                    <li class="shipInfo_trajectory_li">
                      <p>呼号:{{ datas.info.callSign }}</p>
                      <p>船艏向:{{ datas.info.hdg }}</p>
                    </li>
                    <li class="shipInfo_trajectory_li">
                      <p>MMSI: {{ datas.info.mmsi }}</p>
                      <p>航迹向: {{ datas.info.cog }}</p>
                    </li>
                    <li class="shipInfo_trajectory_li">
                      <p>IMO: {{ datas.info.imo }}</p>
                      <p>航速:{{ datas.info.sog }}</p>
                    </li>
                    <li class="shipInfo_trajectory_li">
                      <p>
                        类型: <span>{{ datas.info.shipType }}</span>
                      </p>
                      <p>目的地:{{ datas.info.dest }}</p>
                    </li>
                    <li class="shipInfo_trajectory_li">
                      <p>状态:{{ datas.info.navStatus }}</p>
                      <p>预到时间: {{ datas.info.etaStd }}</p>
                    </li>
                    <li class="shipInfo_trajectory_li">
                      <p>船长:{{ datas.info.length }}</p>
                      <p>纬度: {{ datas.info.lon }}</p>
                    </li>
                    <li class="shipInfo_trajectory_li">
                      <p>船宽: {{ datas.info.breadth }}</p>
                      <p>经度:{{ datas.info.lat }}</p>
                    </li>
                  </ul>
                </div>
              </div>
            </TabPane>
            <TabPane key="2" tab="轨迹路径" force-render>
              <!-- 船舶轨迹 -->
              <div class="shipInfo_trajectory">
                <div class="shipInfo_trajectory_title">
                  <BadgeRibbon
                    text="轨迹路径"
                    color="#537eec"
                    placement="start"
                  >
                  </BadgeRibbon>
                </div>
                <div class="shipInfo_trajectory_box">
                  <Steps
                    progress-dot
                    :current="datas.trajectorypath?.length"
                    direction="vertical"
                  >
                    <Step
                      :title="item.posTime"
                      v-for="item in datas.trajectorypath"
                      :key="item.posTime"
                    >
                      <template #description
                        >lat:{{ item.lat }} lon:{{ item.lon }}
                        &nbsp;
                        <div style="float: right">
                          <eye-invisible-two-tone
                            two-tone-color="#2d48c3"
                          /></div
                      ></template>
                    </Step>
                  </Steps>
                  <!-- <div class="btn_loadmore" @click="loadMore">加载更多...</div> -->
                </div>
                <div class="btn_timeselect">
                  轨迹对比
                  <template
                    v-for="item in datas.timeSelectData"
                    :key="item.name"
                  >
                    <CheckableTag
                      color="#2d48c3"
                      @click="trajectoryTimeSelect(item)"
                      class="tagselect"
                      v-model:checked="item.ischeck"
                      >{{ item.name }}</CheckableTag
                    >
                  </template>
                </div>
                <div class="time_picker">
                  <RangePicker
                    v-model:value="datas.selectTime"
                    show-time
                    @change="handleTimeChange"
                    v-if="datas.timeSelect1"
                  />
                </div>
              </div>
            </TabPane>
            <TabPane key="3" tab="实时数据">
              <!-- 实时数据 -->
              <div class="shipInfo_trajectory">
                <Table
                  size="small"
                  :columns="datas.columns"
                  :row-key="(record) => record.place"
                  :data-source="datas.trajectoryDatas"
                >
                  <template #bodyCell="{ column }">
                    <template v-if="column.dataIndex === 'name'">34ds</template>
                  </template>
                </Table>
              </div>
            </TabPane>
          </Tabs>
        </div>
      </div>
      <!-- 轨迹播放器 -->
      <div
        class="video_player"
        v-if="datas.showVideoPlayer"
        :style="{ margin: datas.videoplayHeight }"
      >
        <div class="video_player_left">
          <div class="shipInfo_trajectory_title">
            <BadgeRibbon text="轨迹回放" color="#537eec" placement="start">
            </BadgeRibbon>
          </div>
          <div style="margin-top: 10px">
            <Row>
              <Col :span="2">
                <img
                  @click="startPlayPath"
                  class="video_player_img"
                  :src="datas.playsrc"
              /></Col>
              <Col :span="22">
                <Slider
                  @change="valueSpeedChange"
                  color="#2d48c3"
                  v-model:value="datas.valueSpees"
              /></Col>
            </Row>
            <div style="margin-left: 40px">
              <Row>
                <Col :span="12">
                  <span class="video_player_span"
                    >开始时间:{{ datas.trajectorypath[0].posTime }}</span
                  >
                </Col>
                <Col :span="12">
                  <span class="video_player_span"
                    >结束时间:{{
                      datas.trajectorypath[datas.trajectorypath.length - 1]
                        .posTime
                    }}</span
                  >
                </Col>
              </Row>
            </div>
          </div>
          <div style="margin-top: 10px">
            <Row>
              <Col :span="22">
                <template
                  v-for="item in datas.doubleSpeedList"
                  :key="item.speedValue"
                >
                  <Tag
                    style="cursor: pointer"
                    :color="
                      activeSpeed == item.speedValue ? '#537eec' : 'processing'
                    "
                    @click="changeDoubleSpeed(item)"
                  >
                    {{ item.speedLabel }}
                  </Tag>
                </template>
              </Col>
            </Row>
          </div>
          <div class="shipInfo_trajectory_title">
            <BadgeRibbon text="数据测评" color="#537eec" placement="start">
            </BadgeRibbon>
          </div>
          <div class="video_player_data">
            <div class="video_player_data_cell">
              <img
                :src="speedimg"
                style="width: 30px; height: 30px; margin-top: 10px"
              />
              <span style="margin-top: 4px">里程:70km</span>
            </div>
            <div class="video_player_data_cell">
              <img
                :src="time"
                style="width: 30px; height: 30px; margin-top: 10px"
              />
              <span style="margin-top: 4px"> 时间:70h</span>
            </div>
            <div class="video_player_data_cell">
              <img
                :src="speedimg"
                style="width: 30px; height: 30px; margin-top: 10px"
              />
              <span style="margin-top: 4px"> 速度:70vh</span>
            </div>
          </div>
        </div>
        <div class="video_player_right">
          <div style="float: right">
            <CloseCircleOutlined
              size="40"
              @click="
                datas.showVideoPlayer = false;
                datas.showInfo = true;
              "
            />
          </div>
          <Table
            size="small"
            :columns="datas.columnstable"
            :row-key="(record) => record.name"
            :data-source="datas.trajectorypath1"
          >
            <template #bodyCell="{ column, text }">
              <template v-if="column.dataIndex === 'LType'">
                {{ locationType[text] }}
              </template>
              <template v-if="column.dataIndex === 'locations'">
                {{ text }}
              </template>
            </template>
          </Table>
        </div>
      </div>
      <!-- 地图 -->
      <div id="map"></div>
    </Spin>
  </div>
</template>
<script setup>
import { onMounted, reactive, ref, watch, h, createVNode } from "vue";

import "leaflet/dist/leaflet.css";
import axios from "axios";
import movingmarker from "./utils/movingmarker";
import leafletmotion from "./utils/leafletmotion";
import L, { point } from "leaflet";
import play from "../assets/playactive.png";
import playstart from "../assets/playstartactive.png";
import videoplayer from "../assets/videoplayer.png";
import polylinedecorator from "leaflet-polylinedecorator";
import speedimg from "../assets/speed.png";
// import leaflet.motion from 'leaflet.motion'
import {
  InputSearch,
  Step,
  Steps,
  BadgeRibbon,
  Badge,
  Tag,
  RangePicker,
  Tabs,
  TabPane,
  Table,
  Radio,
  RadioGroup,
  CheckableTag,
  notification,
  Popover,
  Input,
  Slider,
  Button,
  Spin,
  Col,
  Row,
  DescriptionsItem,
  Descriptions,
} from "ant-design-vue";
import {
  EyeInvisibleTwoTone,
  SwapRightOutlined,
  CompassOutlined,
  ClockCircleOutlined,
  CodeSandboxOutlined,
  CheckOutlined,
  CloseCircleOutlined,
  RightOutlined,
  LeftOutlined,
  SettingOutlined,
} from "@ant-design/icons-vue";
import moment from "moment";
import car from "../assets/car.png";
import ship from "../assets/shipimg.png";
import feiji from "../assets/feiji.png";
import points from "../assets/point.png";
import points1 from "../assets/mypoints1.png";
import start from "../assets/start.png";
import end from "../assets/end.png";
import { object } from "vue-types";
import { useRoute } from "vue-router";
import time from "../assets/time.png";
import locationx from "../assets/locationx.png";
import kache from "../assets/kache.png";
import playback from "../assets/playback.png";
import mapstyle from "../assets/mapstyle.png";
import notice from "../assets/notice.png";
import setting from "../assets/setting.png";
var height = document.body.scrollHeight;
var width = document.body.scrollWidth;
var marker1 = object;
const value2 = ref();
const locationType = {
  1: "卫星定位",
  2: "基站定位",
  3: "小区码定位",
  4: "手机定位",
  0: "不定位",
};
// 1:卫星定位 2:基站定位 3:小区码定位 4:手机定位 0:不定位

const activeSpeed = ref(1);

const datas = reactive({
  selectTime: [],
  trajectorypath1: [],
  showMapSetting: true, //是否显示地图设置
  voyage_number: {}, //当前航次数据
  showInfo: true,
  moveSpeedList: [], //动画移动的速度数组
  doubleSpeedList: [
    {
      speedValue: 1,
      speedLabel: "正常",
      colors: "processing",
    },
    {
      speedValue: 1.5,
      speedLabel: "1.5X",
      colors: "processing",
    },
    {
      speedValue: 2,
      speedLabel: "2X",
      colors: "processing",
    },
    {
      speedValue: 5,
      speedLabel: "5X",
      colors: "processing",
    },
    {
      speedValue: 10,
      speedLabel: "10X",
      colors: "processing",
    },
  ],
  videoplayHeight: 0,
  mapsettingWidth: 0,
  showVideoPlayer: false,
  valueSpees: 35,
  marks: {
    0: "0°C",
    26: "26°C",
    37: "37°C",
    100: {
      style: {
        color: "#f50",
      },
      label: createVNode("strong", {}, "100°C"),
    },
  },
  styleMapTypeColor: {
    float: "right",
    margin: "0px 35px 0px 0px",
  },
  trajectory_track: [], //播放动画路径的数组
  spinning: "false",
  speedvalue: "6000", //默认播放路径设置的速度
  speed: 8000,
  currentPlace: "上海", //当前船的地点
  currentPlaceTime: "2022-10-17 09:22", //当前船的时间
  searchValue: "", //搜索条件
  destination: "长江口", //目的地
  destinationTime: "2022-10-17 09:22", //预计到达时间
  placeofOrigin: "宁夏", //起始地
  placeofOriginTime: "2012-12-22 14:26", //预计出发时间
  mapTypeactiveKey: "normal", //当前地图类型key
  activeKey: "1",
  info: {}, //船舶信息
  timeSelect1: false,
  trajectoryDatas: [], //轨迹信息
  trajectorypath: [], //轨迹路径
  playsrc: play,
  mappath: "https://gac-geo.googlecnapps.cn/maps/vt?lyrs=m&x={x}&y={y}&z={z}", //瓦片地址
  columns: [
    {
      title: "动态",
      dataIndex: "status_name",
      sorter: true,
      width: "20%",
    },
    {
      title: "港口",
      dataIndex: "place",
      sorter: true,
      width: "20%",
    },
    {
      title: "时间",
      dataIndex: "date",
      sorter: true,
      width: "20%",
    },
  ],
  columnstable: [
    // {
    // title: "序号",
    // dataIndex: "status_name1",
    // width: "20%",
    // },
    {
      title: "定位时间",
      dataIndex: "posTime",
      width: "20%",
    },
    {
      title: "定位状态",
      dataIndex: "LType",
      width: "20%",
    },
    {
      title: "速度",
      dataIndex: "speed",
      width: "20%",
    },
    {
      title: "经度",
      dataIndex: "lat",
      width: "20%",
    },
    {
      title: "纬度",
      dataIndex: "lon",
      width: "20%",
    },
  ],
  //时间段数据
  timeSelectData: [
    {
      name: "最近两周",
      ischek: false,
    },
    {
      name: "最近一周",
      ischek: false,
    },
    {
      name: "自定义",
      ischek: false,
    },
  ],
});
var seqGroup = object;
var mapLayer = object;
var map = object;
var initmapPath = object;
var decorator = object;
var decorator1 = object;
var mapmarker = object;
var markertip = object;
const route = useRoute();

onMounted(() => {
  //获取最新一周的轨迹数据
  datas.selectTime[0] = getRecentDay(40);
  datas.selectTime[1] = getRecentDay(0);
  datas.searchValue = route.query.waybill_no;
  onSearch();
  map = L.map("map", {
    zoomControl: false,
    preferCanvas: true,
    // worldCopyJump: true,
  }).setView([37.772, -122.214], 13);

  map.on("click", (e) => {
    console.log(e);
  });

  mapLayer = L.tileLayer(datas.mappath, {
    maxZoom: 4,
  }).addTo(map);

  let margintop = `${height - 330}px`;
  datas.videoplayHeight = `${margintop} 0px 0px 0px`;
  let marginright = `${width - 240}px`;
  datas.mapsettingWidth = `20px 0px 0px ${marginright}`;
  for (let index = 0; index < datas.trajectorypath.length; index++) {
    datas.moveSpeedList.push(datas.speedvalue);
  }
});
/**
 * 地图样式改变
 */
const maptypeChange = () => {
  if (datas.mapTypeactiveKey == "normal") {
    datas.styleMapTypeColor = {
      float: "right",
      color: "#ffffff",
      margin: "0px 35px 0px 0px",
    };
    datas.mappath =
      "https://gac-geo.googlecnapps.cn/maps/vt?lyrs=m&x={x}&y={y}&z={z}";
    mapLayer.setUrl(
      "https://gac-geo.googlecnapps.cn/maps/vt?lyrs=m&x={x}&y={y}&z={z}"
    );
  } else {
    datas.styleMapTypeColor = {
      float: "right",
      color: "#5d85e5",
      margin: "0px 35px 0px 0px",
    };
    datas.mappath =
      "https://gac-geo.googlecnapps.cn/maps/vt?lyrs=s&x={x}&y={y}&z={z}";
    mapLayer.setUrl(
      "https://gac-geo.googlecnapps.cn/maps/vt?lyrs=s&x={x}&y={y}&z={z}"
    );
  }
};
/**
 * 地图路径动画播放
 */
const startPlayPath = () => {
  if (datas.playsrc == playstart) {
    datas.playsrc = play;
    var ships = L.icon({
      iconUrl: ship,
      iconSize: [30, 30],
      iconAnchor: [22, 0],
    });
    if (marker1.isRunning()) {
      clearInterval(index);
      marker1.pause();
    } else {
      index = setInterval(() => {
        datas.valueSpees++;
      }, 1000);
      marker1.start();
    }
    map.setView(datas.trajectorypath[0]);
  } else {
    datas.playsrc = playstart;
    var ships = L.icon({
      iconUrl: ship,
      iconSize: [30, 30],
      iconAnchor: [22, 0],
    });
    if (marker1.isRunning()) {
      clearInterval(index);
      marker1.pause();
    } else {
      index = setInterval(() => {
        datas.valueSpees++;
      }, 1000);
      marker1.start();
    }
    map.setView(datas.trajectorypath[0]);
  }
};

/**
 * 时间段选择 切换
 * @param {*} item
 */
const trajectoryTimeSelect = (item) => {
  const flightPlanCoordinates = [
    { lat: 37.772, lng: -122.214 },
    { lat: 11, lng: -157.821 },
    { lat: -18.142, lng: 178.431 },
    { lat: -27.467, lng: 153.027 },
  ];
  item.color = "#2d48c3";
  if (item.name == "自定义") {
    datas.timeSelect1 = true;
  } else if (item.name == "最近一周") {
    datas.timeSelect1 = false;
    datas.selectTime[0] = getRecentDay(7);
    datas.selectTime[1] = getRecentDay(0);
    onSearch();
  } else if (item.name == "最近两周") {
    datas.timeSelect1 = false;
    datas.selectTime[0] = getRecentDay(14);
    datas.selectTime[1] = getRecentDay(0);
    onSearch();
  }
};
/**
 * 开始播放
 */
var index = object;
const startplay = () => {
  var ships = L.icon({
    iconUrl: ship,
    iconSize: [30, 30],
    iconAnchor: [22, 0],
  });
  if (marker1.isRunning()) {
    clearInterval(index);
    marker1.pause();
  } else {
    index = setInterval(() => {
      datas.valueSpees++;
    }, 1000);
    marker1.start();
  }
  map.setView(datas.trajectorypath[0]);
  L.polyline(datas.trajectorypath).addTo(map);
};
/**
 * 停止播放
 */
const endplay = () => {
  marker1.pause();
};

/**
 * 搜索条件
 */
const onSearch = async () => {
  datas.spinning = "true";
  await axios
    .post("https://twl.tclogx.com/track/TrackSearch/search", {
      search: datas.searchValue,
    })
    .then(function (response) {
      datas.info = response.data.data.info;
      datas.trajectoryDatas = response.data.data.berthing_path;

      datas.voyage_number = response.data.data.voyage_number;
    });
  await axios
    .post("https://twl.tclogx.com/track/TrackSearch/getVesselVoyage", {
      search: datas.searchValue,
      startTime:
        datas.selectTime?.length > 0
          ? datas.selectTime[0]
          : "2022-09-21 00:00:00",
      endTime:
        datas.selectTime?.length > 0
          ? datas.selectTime[1]
          : "2022-10-2 00:00:00",
    })
    .then((res) => {
      datas.spinning = "fasle";
      datas.trajectorypath = res.data.data.trajectory;
      datas.trajectory_track = res.data.data.trajectory_track;
      datas.trajectorypath1 = res.data.data.trajectory.map((d) => {
        return {
          ...d,
          locationtype: locationType[d.LType],
          locations: d.lat,
        };
      });
    })
    .catch(function (error) {})
    .then(function () {});
  datas.moveSpeedList = datas.trajectorypath.map((d) => datas.speedvalue);
  init();
};
/**
 * 初始化地图
 */
const init = () => {
  try {
    //初始化提示信息(到港，靠舶，延误提醒)
    // notification.open({
    // message: "SEAMASTER【已延误5天】",
    // description: "当前位置:上海",
    // icon: h(ClockCircleOutlined, { style: "color: #108ee9;" }),
    // style: "margin-top:50px",
    // });
    // map = L.map("map").setView([51.505, -0.09], 13);
    var ships = L.icon({
      iconUrl: ship,
      iconSize: [30, 30],
      iconAnchor: [22, 0],
    });
    //marker 创建动画

    mapLayer = L.tileLayer(datas.mappath, {
      maxZoom: 19,
    }).addTo(map);
    //获取船舶，轨迹信息
    var ships = L.icon({
      iconUrl: ship,
      iconSize: [30, 30],
      iconAnchor: [22, 0],
    });
    //标记当前位置
    let currentmarker = L.marker([datas.info.lat, datas.info.lon], {
      icon: ships,
    }).addTo(map);
    let popupContents = `<img style='width:40px;height:40px' src='${start}'/>长江口
    <img src='${end}' style='width:40px;height:40px'/>上海<br/>
    <img  style='width:30px;height:30px' src='${kache}'/><span style="margin-left:10px;font-weight:800">湘A326424</span><br/>
    <img src='${time}' style='width:15px;height:15px'/><span style="color:#537eec;margin-left:10px">
     <span> 2022-10-22 19:23</span><br/>
      <img src='${locationx}' style='width:15px;height:15px'/>
      <span style="color:#537eec;margin-left:10px"> lat:${datas.info.lat} lon:${datas.info.lon}</span><br/>
      <img src='${speedimg}' style='width:15px;height:15px'/><span style="color:#537eec;margin-left:10px">${datas.info.sog}</span>
    <br/><img src='${playback}' style='width:30px;height:30px;cursor: pointer;' />
    <span style="margin-left:10px;font-weight:800;cursor: pointer;margin-top:5px">轨迹回放</span>
    `;
    currentmarker.bindPopup(popupContents).openPopup();
    console.log(L, "l");
    var mystartIcon = L.icon({
      iconUrl: start,
      iconSize: [50, 50],
      popupAnchor: [-3, -76],
      shadowUrl: "",
    });
    var myendIcon = L.icon({
      iconUrl: end,
      iconSize: [50, 50],
      popupAnchor: [-3, -76],
      shadowUrl: "",
    });
    // 每个marker显示toolTip 标记起始地，目的地
    datas.trajectorypath.forEach((d, index) => {
      d.lon = d.lon < 0 ? d.lon + 360 : d.lon;
      if (index == 0) {
        // 起点
        mapmarker = L.marker(d, { icon: mystartIcon }).addTo(map);
        markertip = mapmarker
          .bindTooltip(
            `<span style="color:#537eec;margin-left:10px">${d.posTime}</span><br/>`,
            {
              direction: "left",
              offset: L.point(-10, 0),
              opacity: 1,
              permanent: true,
            }
          )
          .openTooltip();
        let popupContent = `<img src='${time}' style='width:15px;height:15px'/><span style="color:#537eec;margin-left:10px">${d.posTime}</span><br/> <img src='${locationx}' style='width:15px;height:15px'/><span style="color:#537eec;margin-left:10px"> lat:${d.lat} lon:${d.lon}</span><br/><img src='${speedimg}' style='width:15px;height:15px'/><span style="color:#537eec;margin-left:10px">${d.speed}</span>`;
        mapmarker
          .bindPopup(popupContent, {
            offset: L.point(3, 80),
          })
          .openPopup();
      } else if (index == datas.trajectorypath.length - 1) {
        // 终点
        mapmarker = L.marker(d, { icon: myendIcon }).addTo(map);
        markertip = mapmarker
          .bindTooltip(
            `<span style="color:#537eec;margin-left:10px">${d.posTime}</span><br/>`,
            {
              direction: "left",
              offset: L.point(-10, 0),
              opacity: 1,
              permanent: true,
            }
          )
          .openTooltip();
        let popupContent = `<img src='${time}' style='width:15px;height:15px'/><span style="color:#537eec;margin-left:10px">${d.posTime}</span><br/> <img src='${locationx}' style='width:15px;height:15px'/><span style="color:#537eec;margin-left:10px"> lat:${d.lat} lon:${d.lon}</span><br/><img src='${speedimg}' style='width:15px;height:15px'/><span style="color:#537eec;margin-left:10px">${d.speed}</span>`;
        mapmarker
          .bindPopup(popupContent, {
            offset: L.point(3, 80),
            autoClose: true,
            // autoPan: true,
          })
          .openPopup();
      } else if (index % 10 == 0) {
        //标记每个点
        var myIcon = L.icon({
          iconUrl: points1,
          iconSize: [7, 7],
          popupAnchor: [-3, -76],
          shadowUrl: point,
        });
        let mapmarker1 = L.marker(d, { icon: myIcon }).addTo(map);

        // if (index % 22 == 0) {
        // mapmarker1.bindTooltip(`${d.posTime}<br/>  `, {
        //   direction: "top",
        //   offset: L.point(-10, 0),
        //   opacity: 0.6,
        //   permanent: true,
        // }).openTooltip;
        let popupContent = `<img src='${time}' style='width:15px;height:15px'/><span style="color:#537eec;margin-left:10px">${d.posTime}</span><br/> <img src='${locationx}' style='width:15px;height:15px'/><span style="color:#537eec;margin-left:8px"> lat:${d.lat} lon:${d.lon}</span><br/><img src='${speedimg}' style='width:15px;height:15px'/><span style="color:#537eec;margin-left:10px">${d.speed}</span>`;
        mapmarker1
          .bindPopup(popupContent, {
            offset: L.point(3, 80),
          })
          .openPopup();
        // }
      }
    });
    let caricon = L.icon({
      iconUrl: car,
      iconSize: [30, 30],
      popupAnchor: [-3, -76],
      shadowUrl: "",
    });
    initmapPath = L.polyline(datas.trajectorypath, {
      color: "#537eec",
      // color:'repeating-linear-gradient(-45deg,var(--van-warning-color) 0,var(--van-warning-color) 20%,transparent 0,transparent 25%,var(--van-primary-color) 0,var(--van-primary-color) 45%,transparent 0,transparent 50%)',
      weight: 6,
    }).addTo(map);
    decorator = L.polylineDecorator(initmapPath, {
      patterns: [
        {
          offset: 0,
          repeat: 50,
          symbol: L.Symbol.arrowHead({
            // 蓝色粗底 白色箭头 修改 weight:6
            pixelSize: 6, // 箭头大小
            headAngle: 60, // 箭头角度
            polygon: false, //箭头是否为多边形
            pathOptions: {
              weight: 3, //箭头粗细
              color: "#ffffff", //箭头颜色
            },

            // 蓝色细底 橙色箭头 修改 weight:3
            // pixelSize: 4,
            // pathOptions: {
            // stroke: true,
            // color: "#fc5531",
            // },

            //部署好的 weight:1.5
            // pixelSize: 6, // 箭头大小
            // headAngle: 45, // 箭头角度
            // polygon: true, //箭头是否为多边形
            // pathOptions: {
            // weight: 4, //箭头粗细
            // color: "#537eec", //箭头颜色
            // },
          }),
        },
      ],
    }).addTo(map);
    map.fitBounds(initmapPath.getBounds());
    //动画轨迹
    marker1 = L.Marker.movingMarker(datas.trajectory_track, 70000, {
      icon: ships,
    }).addTo(map);
  } catch (error) {
    console.log(error, "error");
  }
};
/**
 * 打开轨迹播放器
 */
const showVideoPlayers = () => {
  datas.showVideoPlayer = true;
  datas.showInfo = false;
};
/**
 * 改变倍速
 * @param {*} item
 */
const changeDoubleSpeed = (item) => {
  const speed = {
    1: 10000,
    1.5: 3000,
    2: 6000,
    3: 4000,
    5: 2000,
    10: 1000,
  };
  activeSpeed.value = item.speedValue;
  marker1._durations = marker1._durations.map((d) => {
    if (activeSpeed.value == 1) return d;
    else return d / activeSpeed.value;
  });
};
/**
 * 自定义时间
 */
const handleTimeChange = () => {
  onSearch();
};
/**
 * 获取时间区段
 * @param {*} n
 */
const getRecentDay = (n) => {
  let day = moment(new Date()).subtract(n, "days").format("YYYY-MM-DD");
  console.log(day);
  return day;
};
</script>

<style lang="less">
@import url("https://stackpath.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css");
@import url("//at.alicdn.com/t/c/font_2209979_yg3a7akuly.css");
#map {
  width: 100vw;
  height: 100vh;
}

.search_content {
  position: fixed;
  z-index: 9999;
  width: 400px;
  height: auto;
  padding: 20px;
  margin-left: 20px;
  // margin-top: 50px;
}
.shipInfo {
  background: #ffffff;
  width: 100%;
  height: auto;
  // max-height: 540px;
  margin-top: 15px;
  border-radius: 12px;
  box-shadow: 0 0 25px 1px;
  &_name {
    padding: 12px;
    color: #ffffff;
    height: auto;
    border-top-right-radius: 12px;
    border-top-left-radius: 12px;

    background: -webkit-gradient(
      linear,
      left top,
      right top,
      from(#537eec),
      color-stop(60%, #537eec),
      color-stop(0, #537eec),
      to(#cad3d8)
    );
  }
  &_trajectory {
    padding: 0 12px 10px 20px;
    &_currentplace {
      margin-top: 15px;
      span {
        margin-left: 10px;
      }
    }
    &_box {
      height: auto;
      max-height: 350px;
      overflow: scroll;
    }
    &_title {
      height: 30px;
    }
    &_infocell {
      list-style: none;
    }
    &_path {
      display: flex;
      justify-content: center;
      margin-top: 15px;
      &_mdd {
        &_title {
          margin-left: 20px;
          font-size: 18px;
          font-weight: 600;
        }
      }
      &_qsd {
        &_title {
          margin-right: 20px;
          font-size: 18px;
          font-weight: 600;
        }
      }
    }
  }
}
ul {
  list-style: none;
  padding-left: 15px;
  margin-top: 5px !important;
}
li {
  color: #282828;
  font-size: 13px;
}
p {
  width: 50%;
  box-sizing: border-box;
  // padding-right: 10px;
  display: inline-block;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  word-break: break-all;
  margin: 0 !important;
}
input {
  border-top-left-radius: 10px !important;
  border-bottom-left-radius: 10px !important;
}
button {
  border-top-right-radius: 10px !important;
  border-bottom-right-radius: 10px !important;
}
.ant-input-group-addon {
  position: relative;
  padding: 0 11px;
  color: rgba(0, 0, 0, 0.85);
  font-weight: normal;
  font-size: 14px;
  text-align: center;
  background-color: #fafafa;
  border: 1px solid #d9d9d9;
  border-radius: 10px !important;
  transition: all 0.3s;
}
.ant-steps-vertical > .ant-steps-item .ant-steps-item-icon {
  float: left;
  margin-right: 10px !important;
}
.ant-steps-dot .ant-steps-item-icon,
.ant-steps-dot.ant-steps-small .ant-steps-item-icon {
  width: 6px !important;
  height: 6px !important;
  margin-left: 67px;
  padding-right: 0;
  line-height: 8px;
  background: transparent;
  border: 0;
}
image {
  width: 52px;
  height: 56px;
  transform: translate3d(1319px, 312px, 0px) rotateZ(164deg) rotate;
}
.gm-ui-hover-effect {
  opacity: 0 !important;
}
.gm-style .gm-style-iw-tc::after {
  background: #fff;
  clip-path: polygon(0% 0%, 50% 100%, 100% 0%);
  content: "";
  opacity: 0;
  height: -4px;
  left: 0;
  position: absolute;
  top: -1px;
  width: 25px;
}
.btn_loadmore {
  display: flex;
  justify-content: center;
  cursor: pointer;
  color: #537eec;
}
Tag {
  cursor: pointer;
}
.btn_timeselect {
  margin-top: 10px;
}
.tagselect {
  float: right;
  cursor: pointer;
}
.time_picker {
  z-index: 1;
  margin-top: 10px;
}
.ant-picker-dropdown {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: "tnum";
  position: absolute;
  z-index: 10603 !important;
}
.mostly-customized-scrollbar::-webkit-scrollbar {
  width: 5px;
  height: 8px;
  background-color: #aaa; /* or add it to the track */
}

/* Add a thumb */
.mostly-customized-scrollbar::-webkit-scrollbar-thumb {
  background: #000;
}
.topimg {
  // background-image: url(http://track.sheexpress.com/wp-content/uploads/transparent-map-white.png);
  background-repeat: no-repeat;
  // background: -webkit-gradient(
  //  linear,
  //  left top,
  //  right top,
  //  from(#537eec),
  //  color-stop(60%, #537eec),
  //  color-stop(0, #537eec),
  //  to(#cad3d8)
  //  ) !important;
  // background-color: #537eec;
  width: 100vw;
  height: 60px;
  color: #ffffff;
  line-height: 60px;
  font-weight: 700;
  font-size: 20px;
  padding-left: 20px;
}
.ant-tabs-nav-list {
  padding-left: 10px !important;
}

.leaflet-div-icon {
  background: transparent !important;
  border: none !important;
  color: white;
}
.maptype {
  float: right;
  // margin-top: 100px;
  color: #fff;
  position: fixed;
  z-index: 9999;
}
.ant-radio-wrapper {
  color: white !important;
}
.ant-notification-notice-description {
  z-index: 1000;
}
.ant-radio-wrapper {
  // color: #537eec !important;
  /* color: white !important; */
}

.wx-color {
  color: black !important;
}

.pt-color {
  color: black !important;
}
.ant-popover-title {
  background: -webkit-gradient(
    linear,
    left top,
    right top,
    from(#537eec),
    color-stop(60%, #537eec),
    color-stop(0, #537eec),
    to(#cad3d8)
  ) !important;
}
.video_player {
  position: absolute;
  z-index: 1000;
  width: 99vw;
  margin: 0 auto;
  // height: 420px;
  padding: 20px;
  &_data {
    margin-top: 10px;
    display: flex;
    // flex-direction: column;
    align-content: center;
    justify-content: space-around;
    align-items: center;
    &_cell {
      display: flex;
      flex-direction: column;
      align-content: center;
      justify-content: flex-start;
      align-items: center;
      width: 80px;
      height: 80px;
      cursor: pointer;
      border-radius: 10px;
      color: #537eec;
      border-top: 2px solid #537eec;
      // background: #537eec;
      background: #efeff1;
      opacity: 0.7;
      box-shadow: 0 0 2px 1px;
    }
  }
  // background-color: #ffffff;
  // margin-bottom: 20px;
  display: flex;
  margin-bottom: 20px;
  &_img {
    width: 30px;
    height: 30px;
    margin-top: 5px;
    cursor: pointer;
  }
  &_span {
    color: #5c5959c2;
  }
  &_left {
    width: 30%;
    padding: 20px;
    background: #fff;
    border-radius: 15px;
    height: 300px;
    overflow: scroll;
  }
  &_right {
    margin-left: 20px;
    width: 70%;
    border-radius: 15px;
    padding: 10px 20px;
    background: #fff;
    height: 300px;
    overflow: scroll;
  }
}
.ant-table-thead > tr > th {
  outline: none;
  cursor: pointer;
  color: white;
  background: -webkit-gradient(
    linear,
    left top,
    right top,
    from(#537eec),
    color-stop(80%, #537eec),
    color-stop(0, #537eec),
    to(#537eec)
  ) !important;
  transition: all 0.3s;
}
.showInfo_left {
  margin: 220px 0px 0px -20px;
  position: absolute;
  z-index: 10000;
  height: 80px;
  width: 20px;
  margin-right: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: -webkit-gradient(
    linear,
    left top,
    right top,
    from(#537eec),
    color-stop(80%, #537eec),
    color-stop(0, #537eec),
    to(#537eec)
  ) !important;
  border-top-left-radius: 25px;
  cursor: pointer;
  line-height: 80px;
  border-bottom-left-radius: 25px;
}
.showInfo_right {
  margin: 220px 0px 0px -20px;
  position: absolute;
  z-index: 10000;
  height: 80px;
  width: 20px;
  margin-left: -40px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  background: -webkit-gradient(
    linear,
    left top,
    right top,
    from(#537eec),
    color-stop(80%, #537eec),
    color-stop(0, #537eec),
    to(#537eec)
  ) !important;
  border-top-right-radius: 25px;
  line-height: 80px;
  border-bottom-right-radius: 25px;
}
.map_setting {
  background-color: #ffffff;
  height: auto;
  width: 220px;
  z-index: 1000;
  position: absolute;
  margin-top: 30px;
  float: right;
  border-radius: 10px;
  padding: 10px;
  &_video {
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }
  &_style {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  &_notice {
    // margin-top: 4px;
  }
}

#app {
  width: 100vw;
  height: 100vh;
  overflow: hidden;
}
.ant-radio-group {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: "tnum";
  display: inline-block;
  font-size: 0;
  margin-left: 10px !important;
}
.showSetting_left,
.showSetting_right {
  background: #537eec;
  height: 80px;
  width: 20px;
  display: flex;
  position: absolute;
  float: right;
  right: 0px;
  margin-top: 30px;
  // border-top-left-radius: 10px;
  // border-bottom-left-radius: 10px;
  z-index: 1000;
  align-items: center;
  justify-content: center;
  background: -webkit-gradient(
    linear,
    left top,
    right top,
    from(#537eec),
    color-stop(80%, #537eec),
    color-stop(0, #537eec),
    to(#537eec)
  ) !important;

  cursor: pointer;
  line-height: 80px;
}
.showSetting_left {
  border-top-left-radius: 25px;
  border-bottom-left-radius: 25px;
}
.showSetting_right {
  border-top-right-radius: 25px;
  border-bottom-right-radius: 25px;
}
</style>
