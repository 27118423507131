<template>
  <router-view></router-view>
</template>
<script setup>
</script>
<style>
/* 全局滚动条样式 */
::-webkit-scrollbar {
    width: 5px;
    /* 纵向滚动条*/
    height: 5px;
    /* 横向滚动条 */
    background-color: transparent;
}

/*定义滚动条轨道 内阴影*/
::-webkit-scrollbar-track {
    background-color: transparent;
}

/*定义滑块 内阴影*/
::-webkit-scrollbar-thumb {
    background-color: #929292;
    border-radius: 4px;
}

</style>